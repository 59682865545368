import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
//import { RegisterComponent } from './pages/register/register.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { DeviceDetailsComponent } from './pages/device-details/device-details.component';
import { AuthGuardService } from './guards/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'products', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  //{ path: 'register', component: RegisterComponent, canActivate: [AuthGuardService] },
  { path: 'products', component: DevicesComponent, canActivate: [AuthGuardService] },
  { path: 'products/:id', component: DeviceDetailsComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
