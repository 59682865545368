import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Devices, DevicesCount } from '../interfaces/device';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  urlAPI: string = "https://api.qc.motorline.pt:3000/";

  constructor(public http: HttpClient) { }

  // Get all devices
  public getDevices(): Observable<Devices> {
    let headers: HttpHeaders = new HttpHeaders({
      'x-access-token': (localStorage.getItem('token') === null) ? ' ' : localStorage.getItem('token')
    });

    return this.http.get<Devices>( this.urlAPI + "api/get/products",
      { headers: headers}
    ).pipe(map((devices: Devices) => new Devices(devices)));
  }

  // Get single device
  public getDevice(id: string): Observable<Devices> {
    let headers: HttpHeaders = new HttpHeaders({
      'x-access-token': (localStorage.getItem('token') === null) ? ' ' : localStorage.getItem('token')
    });

    return this.http.get<Devices>( this.urlAPI + "api/get/product/" + id,
      { headers: headers}
    ).pipe(map((devices: Devices) => new Devices(devices))); //devices.map(device => new Device(device))));
  }

  public getDevicesCountByYear(year: number): Observable<DevicesCount> {
    // Set Http headers
    let headers: HttpHeaders = new HttpHeaders({
      'x-access-token': (localStorage.getItem('token') === null) ? ' ' : localStorage.getItem('token')
    });

    // Send GET request to API server to get year devices count
    return this.http.get<DevicesCount>( this.urlAPI + "api/get/products/" + year.toString(),
      { headers: headers }
    ).pipe(map((devicesCount: DevicesCount) => new DevicesCount(devicesCount)));

  }

  public getDevicesCountByMonth(year: number, month: number): Observable<DevicesCount> {
    // Set Http headers
    let headers: HttpHeaders = new HttpHeaders({
      'x-access-token': (localStorage.getItem('token') === null) ? ' ' : localStorage.getItem('token')
    });
    
    // Send GET request to API server to get month devices count
    return this.http.get<DevicesCount>( this.urlAPI + "api/get/products/" + year.toString() + "/" + month.toString(),
      { headers: headers }
    ).pipe(map((devicesCount: DevicesCount) => new DevicesCount(devicesCount)));

  }

  public getDevicesCountByLastWeek(): Observable<DevicesCount> {
    // Set Http headers
    let headers: HttpHeaders = new HttpHeaders({
      'x-access-token': (localStorage.getItem('token') === null) ? ' ' : localStorage.getItem('token')
    });
    
    // Send GET request to API server to get last week devices count
    return this.http.get<DevicesCount>( this.urlAPI + "api/get/products-last-week",
      { headers: headers }
    ).pipe(map((devicesCount: DevicesCount) => new DevicesCount(devicesCount)));

  }

  public getUrl(): string {
    return this.urlAPI
  }


}
