import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { DeviceService } from 'src/app/services/device.service';
import { Router } from '@angular/router';
import { DevicesCount } from 'src/app/interfaces/device';

@Component({
  selector: 'app-devices-chart',
  templateUrl: './devices-chart.component.html',
  styleUrls: ['./devices-chart.component.scss']
})
export class DevicesChartComponent implements OnInit {
  chartMode: string = 'year';
  charChange: boolean = true;

  public devicesChartLabels:string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  public devicesChartData = [ { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Account A' },
                                       { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Account B' }];
  public devicesChartType:string = 'line';
  public devicesChartOptions:any = {
    /*scales: {
      xAxes: [{
          ticks: {
            reverse: true,
            beginAtZero: true
          }
      }]
    }*/
  };

  constructor(private deviceService: DeviceService, private router: Router) {
    /*setInterval(() => {
      console.log("onValUpdate");
      this.onValUpdate();
    }, 2000);*/
    this.onValChange(this.chartMode);
  }

  // events on slice click
  public chartClicked(e:any):void {
    console.log(e);
  }
 
  // event on pie chart slice hover
  public chartHovered(e:any):void {
    console.log(e);
  }

  ngOnInit() {}

  // Call when toggle button value change
  onValChange(value: string) {
    this.chartMode = value;
    console.log(this.chartMode);

    this.charChange = true;
    switch (value) {
      case 'week':
        //this.setChartMode(7, this.weekDays());
        this.getDevicesCountByLastWeek();
        break;
      case 'month':
        //this.setChartMode(this.daysInThisMonth(), Array.from(new Array(this.daysInThisMonth()),(val,index)=>index + 1));
        this.getDevicesCountByMonth();
        break;
      default:
        //this.setChartMode(12, Array.from(new Array(12),(val,index)=>index + 1));
        this.getDevicesCountByYear();
        break;
    } 
  }

  onValUpdate() {
    switch (this.chartMode) {
      case 'week':
        this.getDevicesCountByLastWeek();
        break;
      case 'month':
        this.getDevicesCountByMonth();
        break;
      default:
        this.getDevicesCountByYear();
        break;
    }
  }

  // Get number of days that the current month have
  daysInThisMonth() {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
  }

  weekDays(): any {
    let now = new Date();
    let weekdays = [];
    for (let i = 0; i < 7; i++) {
      weekdays.push(new Date(now.getFullYear(), now.getMonth(), now.getDate() - i).toLocaleString('pt-PT', {weekday: 'short'}));
    }

    return weekdays; //.reverse();
  }

  // Get year device count
  getDevicesCountByYear() {
    this.deviceService.getDevicesCountByYear(new Date().getFullYear()).subscribe((resp: DevicesCount) => {
      this.updateCount(resp, Array.from(new Array(12),(val,index)=>index + 1)  );
    }, (err => {
      // Delete token and redirect to login
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }));
  }

  // Get month device count
  getDevicesCountByMonth() {
    this.deviceService.getDevicesCountByMonth(new Date().getFullYear(), new Date().getMonth()).subscribe((resp: DevicesCount) => {
      this.updateCount(resp, Array.from(new Array(this.daysInThisMonth()),(val,index)=>index + 1) );
    }, (err => {
      // Delete token and redirect to login
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }));
  }

  // Get last week count
  getDevicesCountByLastWeek() {
    this.deviceService.getDevicesCountByLastWeek().subscribe((resp: DevicesCount) => {
      this.updateCount(resp, this.weekDays());
    }, (err => {
      // Delete token and redirect to login
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }));
  }

  async updateCount(resp: DevicesCount, labels: Array<any>) {
    if (resp.status == "error") {
      // Delete token and redirect to login
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }

    console.log(resp.data);
    
    if (this.charChange) {
      this.charChange = false;
      this.devicesChartData = [];
      this.devicesChartLabels = labels;
      await resp.data.forEach((device, x) => {
        this.devicesChartData.push({
          data: new Array(labels.length).fill(0),
          label: resp.data[x]._id
        });
      });
    }

    /*
    await resp.data.forEach((device, x) => {
      //this.chart.data.datasets[x].data = new Array(this.chart.data.datasets.data.length).fill(0);
      console.log(this.chart.data.datasets[x].data);
    });
    */

    await resp.data.forEach((device, x) => {
        device.data.forEach((data, y) => {
        this.devicesChartData[x].data[data.value - 1] = data.count;
      });
    } );
  }

}
