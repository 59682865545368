import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DeviceService } from '../../services/device.service';
import { Devices, Device, DevicesCount} from '../../interfaces/device';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Chart } from 'chart.js';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, AfterViewInit {

  devices: Device[] = [];

  step: number = -1;
  public displayedColumns = ['date', 'id', 'employer'];
  public dataSource = new MatTableDataSource<Device>();
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(private deviceService: DeviceService, private router: Router) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.deviceService.getDevices().subscribe((resp: Devices) => {
      if (resp.status == "error") {
        // Delete token and redirect to login
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
        return;
      }
      this.devices = resp.products;
      this.dataSource.data = resp.products;
      console.log(resp);

    });

  }

  getUrl(): string {
    return this.deviceService.getUrl();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;

    if (this.step >= this.devices.length)
      this.step = 0;

    console.log(this.step);
  }

  prevStep() {
    this.step--;

    if (this.step < 0)
      this.step = this.devices.length - 1;

    console.log(this.step);
  }

  getDetails(id: string) {
    this.router.navigate(['/products/' + id]);
  }

}
