import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Login } from '../../interfaces/login';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public submitted: Boolean;
  public errorMessages: Array<String> = [];

  public returnUrl;

  constructor(private user: UserService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/products';
  }

  login() {
    console.log("login");
  }

  onSubmit() {
    this.submitted = true;
    this.errorMessages = [];

    // stop here if form is invalid
    if (this.loginForm.valid) {
      console.log('Form valid');
      this.user.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value).subscribe((resp: Login) => {
        if (resp.status == "error") {
          // ToDo: show error message in the page
          console.log(resp.status);
          this.errorMessages.push(resp.message);
        }
        // Save token on local storage
        localStorage.setItem('token', resp.data.token);

        // Navigate to devices page
        this.router.navigateByUrl(this.returnUrl);

        console.log(resp);      
      }, (err => {
        console.log(err);
        this.errorMessages.push(err.error.message);
      }));
    } else {
      console.log('Form invalid');
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

}
