export class Login {
    status: string;
    message: string;
    data: {
        name: string;
        token: string;
    };

    constructor(login: Login) {
        this.status = login.status;
        this.message = login.message;
        this.data = login.data;
    }
}