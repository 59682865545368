import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from '../interfaces/login';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient, private router: Router) { }

  // Get all devices
  public login(email: string, password: string): Observable<Login> {
    let headers: HttpHeaders  = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let body = new URLSearchParams();
    body.set('email', email);
    body.set('password', password);

    return this.http.post<Login>("https://api.qc.motorline.pt:3000/users/authenticate", body.toString(),
      { headers: headers}
    ).pipe(map((devices: Login) => new Login(devices)));//devices.map(device => new Device(device))));

  }

  public logout() {
    // Delete token and redirect to login
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  // Is the user authenticated
  public isAuthenticated(): boolean {
    if (localStorage.getItem('token'))
      return true;

    return false;
  }
}
