import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from '../../services/device.service';
import { Devices, Device } from '../../interfaces/device';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss']
})
export class DeviceDetailsComponent implements OnInit {

  devices: Device[] = [];

  constructor(private route: ActivatedRoute, private router: Router, 
    private deviceService: DeviceService, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    this.deviceService.getDevice(this.route.snapshot.paramMap.get('id')).subscribe((resp: Devices) => {
      if (resp.status == "error") {
        // Delete token and redirect to login
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
      }
      this.devices = resp.products;
      console.log(resp);
      console.log(this.deviceService);
    });
  }

  getUrl() {
    return this.document.location.origin;
    //return this.router.url;
  }

}
