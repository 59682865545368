export class Devices {
    status : string;
    message: string;
    products   : Array<Device>;

    constructor(devices: Devices) {
        this.status  = devices.status;
        this.message = devices.message;
        this.products    = devices.products;
    }
}

export interface Device {
    id      : string;
    employer: string;
    type    : string;
    data    : Array<DeviceData>;
}

interface DeviceData {
    info : string;
    value: string;
    icon : string;
    color: string;
}

// Year counter
export class DevicesCount {
    status: string;
    data: Array<DevicesCountType>;

    constructor(devicesCount: DevicesCount) {
        this.status = devicesCount.status;
        this.data = devicesCount.data;
    }
}

interface DevicesCountType {
    _id  : string;
    data: Array<DevicesCountData>;
}

interface DevicesCountData {
    value  : number;
    count  : number;
}
