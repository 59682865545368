import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

// Material imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule, MatButtonModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatListModule, MatInputModule, MatFormFieldModule, MatGridListModule,
          MatSliderModule, MatCardModule, MatExpansionModule, MatTableModule, MatSortModule, MatPaginatorModule, MatButtonToggleModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigationComponent } from './navigation/navigation.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { DeviceDetailsComponent } from './pages/device-details/device-details.component';

import { QRCodeModule } from 'angularx-qrcode';
import { ChartsModule } from 'ng2-charts';
import { LayoutModule } from '@angular/cdk/layout';
import { DevicesChartComponent } from './components/devices-chart/devices-chart.component';

//import { NbThemeModule, NbCardModule, NbLayoutModule } from '@nebular/theme';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    NavigationComponent,
    DevicesComponent,
    DeviceDetailsComponent,
    DevicesChartComponent
  ],
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule, MatButtonModule, LayoutModule, MatToolbarModule, MatSidenavModule, MatIconModule, MatListModule, MatInputModule, MatFormFieldModule, MatGridListModule,
    MatSliderModule, MatCardModule, MatExpansionModule, MatTableModule, MatSortModule, MatPaginatorModule, MatButtonToggleModule,
    FlexLayoutModule,
    QRCodeModule,
    ChartsModule,
    /*
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule, NbCardModule */
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
